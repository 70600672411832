var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "version": "1.0",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "150.000000pt",
      "preserveAspectRatio": "xMidYMid meet",
      "viewBox": "0 0 139 95",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "height": "24"
    }
  }, [_c('g', {
    attrs: {
      "transform": "translate(0.000000,84.000000) scale(0.100000,-0.100000)",
      "fill": "#000000",
      "stroke": "none"
    }
  }, [_c('path', {
    attrs: {
      "d": "M512 558 c-25 -25 -14 -54 28 -71 22 -8 40 -20 40 -26 0 -15 -36 -14\n-60 1 -29 18 -39 -1 -11 -23 29 -24 52 -24 81 -1 31 25 19 61 -21 69 -34 7\n-46 20 -30 36 7 7 16 6 29 -6 23 -21 32 -21 32 -2 0 29 -65 46 -88 23z"
    }
  }), _c('path', {
    attrs: {
      "d": "M677 503 c-15 -41 -21 -69 -15 -72 6 -2 15 4 20 13 11 20 57 21 64 1\n4 -8 13 -15 21 -15 16 0 14 11 -17 90 -26 67 -43 63 -73 -17z m53 -9 c0 -8 -7\n-14 -15 -14 -17 0 -18 2 -9 24 7 18 24 12 24 -10z"
    }
  }), _c('path', {
    attrs: {
      "d": "M837 563 c-4 -3 -7 -35 -7 -70 l0 -63 50 0 c28 0 50 5 50 10 0 6 -16\n10 -35 10 l-35 0 0 60 c0 55 -6 70 -23 53z"
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }